<template>
  <div class="contactNumber">
    <crumbs :item="title"></crumbs>
    <div class="banner">
      <img
        src="@/static/images/secondaryMenu/disclosure/institutions_banner@2x.png"
        alt=""
      />
    </div>
    <div class="tabs_box">
      <div class="tabs">
        <el-tabs v-model="zid" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in area"
            :label="item.areaName"
            :key="item.zid"
            :name="item.zid.toString()"
          >
          最新信息披露日期:{{item.pubLishTime}}
        </el-tab-pane>
          <!--          <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>-->
          <!--          <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>-->
          <!--          <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>-->
        </el-tabs>
      </div>
      <ul class="main">
        <template v-if="total">
          <li v-for="(item, index) in phone" :key="index">
            <p class="main_company">{{ item.companyTitle }}</p>
            <div>
              <div class="main_address">
                <p>地址：</p>
                <div>{{ item.companyAddress }}</div>
              </div>
              <div class="zipCode">
                <p>邮编：</p>
                <div>{{ item.companyPostcode }}</div>
              </div>
              <div class="phone">
                <p>电话：</p>
                <div>{{ item.companyTelphone }}</div>
              </div>
              <div class="fax" v-if="item.companyFax">
                {{ item.companyFax }}
              </div>
            </div>
          </li>
          <Paging
            class="page"
            :total="total"
            :pageNum="pageNum"
            :pages="pages"
          ></Paging>
        </template>
        <template v-else>
          <empty></empty>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
import empty from "@/components/empty";
import Paging from "@/components/paging.vue";
import { getContentBranchListPro, getContentAreaList } from "@/api/public";

const region = {
  北京: "beijing",
  北京1: "beijing1",
  北京2: "beijing2",
  广东: "guangdong",
  湖北: "hubei",
  江苏: "jiangsu",
  青岛: "qingdao",
  山东: "shandong",
  上海: "shanghai",
  四川: "sichuan",
  深圳: "shenzhen",
  浙江: "zhejiang",
  宁波: "ningbo",
};
export default {
  name: "contactNumber",
  data() {
    return {
      title: [],
      activeName: "1",

      region,
      phone: [],
      area: null,
      zid: undefined,
      total: null,
      pageNum: 0,
      pages: 0,
      link: "",
    };
  },
  components: { crumbs, empty, Paging },
  created() {
    console.log(this.$route.query);
    // if (this.$route.query.zid) {
    //   console.log("this.$route.query.zid", this.$route.query.zid);
    //   this.zid = this.$route.query.zid;
    //   console.log(this.zid, "this.zid");
    // }
    console.log(this.zid);
    console.log(window.location.href);
    this.title = [
      {
        title: "公开信息披露",
        link: "",
      },
      {
        title: "基本信息",
        link: "",
      },
      {
        title: "公司概况",
        link: "",
      },
      {
        title: "各分支机构营业场所和联系电话",
        link: `/preview/public/branchOffice`,
      },
    ];
    // this.request();
    this.areaList();
  },
  methods: {
    async areaList() {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getContentAreaList({
          timeStamp,
          sign,
          modelType: "pre",
        });
        console.log("分支机构区域", res.data);
        if (res.data.code == 200) {
          console.log(res.data.data);
          this.area = res.data.data;
          this.area.sort((a, b) => a.categoryShowindex - b.categoryShowindex);
          console.log("this.area", this.area);
          if (this.$route.query.zid) {
            this.zid = this.$route.query.zid;
            console.log("存在", this.zid);
          } else {
            this.zid = this.area[0].zid.toString();
            console.log("bu存在", this.zid);
          }
        }
        console.log("this.zid", this.zid);
        this.request(this.zid);
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    async request(num) {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getContentBranchListPro({
          timeStamp,
          sign,
          zid: num,
        });
        console.log("分支机构", res.data);
        if (res.data.code == 200) {
          console.log(res.data.rows);
          this.phone = res.data.rows;
          this.total = res.data.total;
          this.pageNum = res.data.pageNum;
          this.pages = res.data.pages;
          console.log(this.total);
        }
        // if (
        //   res.data.code == 401 ||
        //   res.data.code == 404 ||
        //   res.data.code == 500
        // ) {
        //   this.$router.replace("/failure");
        // }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      console.log("zid", tab.name);
      this.zid = tab.name;
      console.log(this.activeName);
      this.request(this.zid);
    },
  },
};
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

.contactNumber {
  width: 100%;

  .banner {
    width: 80%;
    margin: 5rem auto 2rem;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .tabs_box {
    width: 80%;
    margin: auto;

    .tabs {
      width: 85%;
      margin: auto;
    }

    .main {
      width: 85%;
      margin: 1rem auto;
      //border: 1px solid;
      display: flex;
      flex-wrap: wrap;
      //height: 33vw;
      li {
        margin: 2rem 0;
        width: 45%;
        > .main_company {
          font-size: 2.5rem;
          font-weight: 700;
        }

        > div {
          margin: 1.5rem 0 0;
          font-size: 1.5rem;
          > div {
            display: flex;
            > p {
              //width: 5rem;
              margin-right: 1rem;
            }
            > div {
              width: 80%;
            }
          }
        }
      }
      li:nth-child(2n) {
        margin-left: 10%;
      }
    }
  }

  /deep/ .el-tabs__item {
    padding: 0 18px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    position: relative;
  }
  /deep/ .page {
    width: 100%;
    margin: 4rem 0;
    padding: 0;
  }
  ::v-deep .el-tabs__item {
    font-size: 2rem !important;
  }
}
</style>