<template>
  <div class="paging">
    <!-- <p v-if="tab == 1">共有{{ data.length }}条记录</p> -->
    <p>共有{{ total }}条记录</p>
    <div>
      <img
          @click="getPageNumAReduction()"
          src="~@/static/images/l_arrow@2x.png"
          alt=""
      />
      <span>{{ pages? pageNum:0}}/{{ pages }}</span>
      <img
          @click="getPageNumAdd()"
          src="~@/static/images/ic_arrow_black@2x.png"
          alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name:"paging",
  data(){
    return{

    }
  },
  props:{
    total:{
      type:Number
    },
    pageNum:{
      type:Number,
    },
    pages:{
      type:Number,
    },
    length:{
      type:Number,
      default:0
    }
  },
  methods:{
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.pageNum);
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.pageNum);
      }
    },
  }
};
</script>

<style lang="less" scoped>
.paging {
  display: flex;
  font-size: 2rem;
  align-items: center;
  margin: 3rem 0rem 0;
  justify-content: space-between;
  padding: 0 2rem;
  width: 70%;

  > div {
    display: flex;
    align-items: center;
    width: 20rem;
    justify-content: space-between;

    img {
      width: 3rem;
      height: 2rem;
      cursor: pointer;
    }
  }
}
</style>